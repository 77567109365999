<template>
  <div style="min-height:100vh;">
    <div class="worksbox" :style="zptype" v-loading="loadingworksbox">
      <div class="worksbox_t">
        <div class="worksbox_tl">
          <ul class="worksbox_tll">
            <li @click="celan(1)"><span>返回</span></li>
            <li
              v-if="
                chengguo.video != '' &&
                chengguo.video != null &&
                videotype === true
                  ? true
                  : false
              "
              @click="celan(2)"
            >
              <span>视频介绍</span>
            </li>
            <li @click="celan(3)"><span>作品图集</span></li>
            <li @click="celan(4)"><span>作品介绍</span></li>
            <li @click="celan(5)"><span>评论</span></li>
          </ul>
          <div class="worksbox_tlr">
            <div class="worksbox_tlrlogo">
              <img :src="draw_info.logo" alt="" />
            </div>
            <div class="worksbox_tlrbtm">
              <div class="worksbox_tlrbtm_c">
                <div class="worksbox_tlrbtmuimg">
                  <img :src="chengguo.userpic" alt="" />
                </div>
                <p class="worksbox_tlrbtmname">{{ chengguo.username }}</p>
                <p class="worksbox_tlrbtmtype">{{ chengguo.major }}</p>
                <div
                  class="worksbox_tlrbtmgz"
                  @click="gztypeclick"
                  :class="gztype == '1' ?'gztypeclickcss':'worksbox_tlrbtmgz'"
                  v-if="usergztype"
                >
                  {{ gztype == "1" ? "已关注" : "关注" }}
                </div>
                <audio
                  :src="chengguo.audio"
                  v-if="
                    chengguo.audio != '' && chengguo.audio != null
                      ? true
                      : false
                  "
                  controls="controls"
                ></audio>
                <div class="worksbox_tlrbtmjj">{{ chengguo.saytext }}</div>
                <p class="worksbox_tlrbtmtime">
                  发布时间：{{ chengguo.create_time }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="worksbox_tr">
          <div class="worksbox_trt">
            <div class="worksbox_trtl">
              <div></div>
            </div>
            <div class="worksbox_trtr">和作营 HOZOIN</div>
          </div>
          <h2 class="worksbox_trtitle">{{ chengguo.title }}</h2>
          <video
            v-if="
              chengguo.video != '' &&
              chengguo.video != null &&
              videotype === true
                ? true
                : false
            "
            style="
              max-width: 90%;
              height: auto;
              margin: 0 auto 2rem;
              display: block;
            "
            id="worksz_sxq"
            :style="zptype"
            :poster="chengguo.titlepic"
            :src="chengguo.video"
            controls="controls"
          ></video>
          <ul id="Chengguo_c_imgallul_works" class="Chengguo_c_imgallul" :style="zptype">
            <li
              v-for="(item, index) in imgalll"
              :key="index"
              v-show="item != ''"
            >
              <img :src="item" alt="" />
            </li>
          </ul>
          <div id="Chengguo_ccenter_wokrs" class="Chengguo_ccenter" v-html="chengguo.newstext"></div>
        </div>
      </div>
    </div>
    <div class="Chengguo_c">
      <div class="chengguotop" :style="zptype_flex">
        <div
          class="chengguotop_right"
          :style="zhifuclicktype === true ? '' : 'width:100%;'"
        >
          <div class="chengguotop_rightl">
            <p
              @click="chengguotitle(chengguotitlestylenum)"
              :style="chengguotitlestyle"
            >
              {{ chengguo.title }}
            </p>
            <p>发布时间：{{ chengguo.create_time }}</p>
            <p v-if="chengguo.major != 0">专业：{{ chengguo.major }}</p>
            <p v-if="chengguo.major != 0 && chengguo.teacher != ''">
              导师：{{ chengguo.teacher }}
            </p>

            <div class="chengguotop_rightbox">
              <div class="chengguotop_rightboximg">
                点赞：{{
                  chengguo.give_like != null ? chengguo.give_like : "0"
                }}
              </div>
              <div class="chengguotop_rightboximg">
                热度：{{ chengguo.browse != null ? chengguo.browse : "0" }}
              </div>
              <div class="chengguotop_rightboximg">
                评论：{{ chengguo.plnum != null ? chengguo.plnum : "0" }}
              </div>
            </div>
          </div>
          <div
            class="chengguotop_rightr"
            :style="chengguo.money == 0 ? 'border:none;' : ''"
          >
            <div class="chengguotop_rightrtop">
              <div class="chengguotop_rightrtopl">
                <p>{{ chengguo.username }}</p>
                <p>{{ chengguo.schoolname }}</p>
              </div>
              <router-link :to="userlink">
                <div class="chengguotop_rightrtopr">
                  <img :src="chengguo.userpic" alt="" />
                </div>
              </router-link>
            </div>
            <div class="chengguotop_rightrbtm">
              <div @click="gztypeclick" v-if="usergztype">
                {{ gztype == "1" ? "已关注" : "关注" }}
              </div>
              <div
                v-if="gid == 2"
                @click="fabulink(chengguo.userid, chengguo.username)"
              >
                雇佣
              </div>
            </div>
          </div>
        </div>
        <div v-if="zhifuclicktype" class="chengguotop_left">
          <a :href="downworks" ref="downworksref"></a>
          <p>出售价格</p>
          <p>{{ chengguo.money == 0 ? "免费" : "￥" + chengguo.money + "" }}</p>
          <div @click="zhifuclick">{{ zhifutext }}</div>
        </div>
      </div>
      <div
        id="videos"
        v-if="
          chengguo.video != '' && chengguo.video != null && videotype === false
            ? true
            : false
        "
        :style="zptype_f"
      >
        <img
          v-if="videostype"
          class="videosimg"
          :src="chengguo.titlepic"
          alt=""
        />
        <img
          @click="bf"
          class="videosimgbtn"
          v-if="videostype"
          src="https://task.hozoin.cn/Works/20210223/sj-6043917379.png"
          alt=""
        />
      </div>
      <video
        v-if="
          chengguo.video != '' && chengguo.video != null && videotype === true
            ? true
            : false
        "
        style="
          max-width: 100%;
          height: 50vh;
          margin: 0 auto 2rem;
          display: block;
        "
        :style="zptype_f"
        :poster="chengguo.titlepic"
        :src="chengguo.video"
        controls="controls"
      ></video>
      <div class="mp3box" :style="zptype_f">
        <audio
          :src="chengguo.audio"
          v-if="chengguo.audio != '' && chengguo.audio != null ? true : false"
          controls="controls"
        ></audio>
      </div>

      <ul class="Chengguo_c_imgallul" :style="zptype_f">
        <li v-for="(item, index) in imgalll" :key="index">
          <img :src="item" alt="" />
        </li>
      </ul>
      <div
        class="Chengguo_ccenter"
        :style="zptype_f"
        v-html="chengguo.newstext"
      ></div>
      <Sjpinglun :all="pinglunall" />
      <zan :all="zanall" />
      <div class="Chengguo_cqtbox">
        <p class="Chengguo_cqttitle">其他作品</p>
        <ul class="rencai_cul">
          <li
            class="rencaiulboxli_rightli"
            v-for="(item, index) in chengguo_btmul"
            :key="index"
            @click="xiangmuc(item.id)"
          >
            <img :src="item.titlepic" alt="" />
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
      <zhifu :all="dataall" />
      <el-dialog
        title="请前往百度网盘下载资料！"
        :visible.sync="yasuobaotp"
        width="30%"
      >
        <div class="baiduzipbox">
          <div class="baiduzipboxspan">网盘地址：{{ cloud_disk }}</div>
          <div class="baiduzipboxspan">提取码：{{ extract_code }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="yasuobaotp = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import zan from "../components/zan";
import zhifu from "../components/zhifu";
import Sjpinglun from "../components/Sjpinglun";
export default {
  components: {
    zan,
    zhifu,
    Sjpinglun,
  },
  data() {
    return {
      loadingworksbox:true,
      draw_info: {},
      zptype: "display:block;",
      zptype_f: "display:none",
      zptype_flex: "display:none",
      userlink: "",
      cloud_disk: "",
      extract_code: "",
      yasuobaotp: false,
      videostype: true,
      chengguotitlestyle: "",
      chengguotitlestylenum: "0",
      usergztype: false,
      downworks: "",
      zhifutext: "立即购买",
      pinglunall: {
        classid: "4",
        id: "",
      },
      dataall: {
        type: false,
        money: "",
        ddnnum: "",
        class_id: "4",
      },
      chengguo: {},
      chengguo_btmul: [],
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      userinfo: {},
      gztype: "",
      userinfo: {},
      imgalll: [],
      gid: "0",
      zanall: {
        classid: "4",
        id: "0",
      },
      Orderid: "",
      ddnnum: 0,
      new_playauth: "",
      new_videoid: "",
      zhifuclicktype: false,
      videotype: false,
    };
  },
  created() {
    let that = this;
    that.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    if (that.userinfo != null) {
      that.gid = that.userinfo.groupid;
    }
    let id = that.$route.query.data;
    that.zanall.id = id;
    that.pinglunall.id = id;
    this.$axios({
      url: "/api/getReviewDetails",
      method: "post",
      data: {
        id,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.userlink = "/Rencai_c?data=" + res.data.result.userid;
        that.chengguo = res.data.result;
        that.loadingworksbox=false;
        if (
          res.data.result.word_type == "2" &&
          res.data.result.tfdesign == "2"
        ) {
          //作品展
          that.zptype = "display:block;";
          that.zptype_f = "display:none;";
          that.zptype_flex = "display:none;";
          that.draw_info = JSON.parse(res.data.result.draw_info);
        }
        let titlename = "";
        if (
          res.data.result.schoolname != null &&
          res.data.result.schoolname != ""
        ) {
          titlename = res.data.result.schoolname;
        } else {
          titlename = "和作营";
        }
        document.title = res.data.result.title + "_" + titlename;

        document
          .querySelector('meta[name="KeyWords"]')
          .setAttribute(
            "content",
            "" +
              res.data.result.title +
              ",毕设展,毕业设计展," +
              titlename +
              ",2021毕业设计展"
          );
        if (res.data.result.video != null && res.data.result.video != "") {
          let videotype = res.data.result.video.slice(0, 4);
          if (videotype == "http") {
            that.videotype = true;
          } else {
            that.videotype = false;
          }
        }
        this.fenxiang(
          res.data.result.title,
          res.data.result.newstext,
          res.data.result.titlepic
        );
        that.imgalll = res.data.result.atlas_img.split(",");
        if (res.data.result.money == 0) {
          that.zhifuclicktype = false;
        }
        this.buytype(res.data.result.userid);
        if (that.userinfo != null) {
          if (that.userinfo.userid == res.data.result.userid) {
            that.usergztype = false;
          } else {
            that.usergztype = true;
          }
          that
            .$axios({
              url: "/api/getFollowFans",
              method: "post",
              data: {
                userid: that.userinfo.userid,
                f_type: "1",
                page: "1",
                limit: "100000",
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                let fensi = res.data.result.list;
                for (let i = 0; i < fensi.length; i++) {
                  if (fensi[i].userid == that.chengguo.userid) {
                    that.gztype = "1";
                    return;
                  } else {
                    that.gztype = "0";
                  }
                }
              } else {
                console.log("请求失败");
              }
            });
        } else {
          that.usergztype = true;
        }
        //////         ////////

        //////         ///////
      } else {
        console.log("请求失败");
      }
    });

    //////////////////////
    this.$axios({
      url: "/api/getReviewPhotoList",
      method: "post",
      data: {
        activity_one: "",
        activity_two: "",
        page: "1",
        limit: "6",
        gztype: "0",
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.chengguo_btmul = res.data.result.list;
      } else {
        console.log("请求失败");
      }
    });
    ////////////////
  },
  methods: {
    gztypeclick() {
      let that = this;
      if (that.userinfo != null) {
        let gztypett = "";
        if (this.gztype == "1") {
          gztypett = "getCancelFollow";
        } else {
          gztypett = "getFollow";
        }
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/" + gztypett + "",
          method: "post",
          data: {
            userid: that.userinfo.userid,
            member_id: that.chengguo.userid,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            if (that.gztype == 0) {
              that.gztype = 1;
              that.$message({
                message: "添加关注成功",
                type: "success",
                duration: 1000,
              });
            } else {
              that.gztype = 0;
              that.$message({
                message: "取消关注成功",
                type: "success",
                duration: 1000,
              });
            }
            this.$forceUpdate();
          } else {
            console.log("请求失败");
          }
        });
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
    xiangmuc(id) {
      this.$router.push({
        path: "/Chengguo_c",
        query: { data: id },
      });
    },
    fabulink(id, username) {
      this.$router.push({ path: "/Sjfabu", query: { data: id, un: username } });
    },
    zhifuclick() {
      let that = this;
      if (that.userinfo != null) {
        if (that.Orderid == 0) {
          if (that.ddnnum == 0) {
            this.$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/getUserWorksPay",
              method: "post",
              data: {
                money: that.chengguo.money,
                id: that.zanall.id,
                title: that.chengguo.title,
              },
            }).then((res) => {
              if (res.data.code == 1) {
                that.ddnnum = res.data.result;
                that.dataall = {
                  type: true,
                  money: that.chengguo.money,
                  ddnnum: res.data.result,
                  class_id: "4",
                };
              } else {
              }
            });
          } else {
            that.dataall = {
              type: true,
              money: that.chengguo.money,
              ddnnum: that.ddnnum,
              class_id: "4",
            };
          }
        } else {
          if (
            that.downworks == "" &&
            that.cloud_disk == "" &&
            that.extract_code == ""
          ) {
            that.$message({
              message: "此作品暂无压缩包",
              duration: 1000,
            });
          } else {
            if (that.downworks != "") {
              that.$refs.downworksref.click();
            } else {
              //网盘
              that.yasuobaotp = true;
            }
          }
        }
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
    buytype(uid) {
      let that = this;
      if (that.userinfo != null) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserOrderInfo",
          method: "post",
          data: {
            id: that.zanall.id,
            classid: "4",
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.Orderid = res.data.result;
            if (res.data.result == 0) {
              if (that.userinfo.userid == uid) {
                that.Orderid = "1";
                that.zhifutext = "立即下载";
                that.downurl();
              } else {
                if (that.chengguo.money > 0) {
                  that.zhifutext = "立即购买";
                  that.zhifuclicktype = true;
                } else {
                  that.Orderid = "1";
                  that.zhifutext = "立即下载";
                  that.downurl();
                }
              }
            } else {
              that.zhifutext = "立即下载";
              that.zhifuclicktype = true;
              that.downurl();
            }
          } else {
          }
        });
      } else {
        that.zhifuclicktype = true;
        if (that.chengguo.money == "0") {
          that.zhifuclicktype = false;
        } else {
          that.zhifutext = "立即购买";
        }
      }
    },
    downurl() {
      let that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/userWorksFile",
        method: "post",
        data: {
          id: that.zanall.id,
        },
      }).then((res) => {
        that.downworks = res.data.result.file_url;
        that.cloud_disk = res.data.result.cloud_disk;
        that.extract_code = res.data.result.extract_code;
        if (
          that.cloud_disk != "" ||
          that.extract_code != "" ||
          that.downworks != ""
        ) {
          that.zhifuclicktype = true;
        }
      });
    },
    chengguotitle(id) {
      if (id == "0") {
        this.chengguotitlestyle = "display:block;height:auto;";
        this.chengguotitlestylenum = "1";
      } else {
        this.chengguotitlestyle = "display:-webkit-box;height:3.6rem;";
        this.chengguotitlestylenum = "0";
      }
    },
    bf() {
      let that = this;
      if (that.userinfo != null) {
        that.videostype = false;
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getPlayVideo",
          method: "post",
          data: {
            video_id: that.chengguo.video,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.new_playauth = res.data.result.PlayAuth;
            that.new_videoid = res.data.result.VideoMeta.VideoId;
            let width = Number(document.documentElement.clientWidth);
            if (width > 800) {
              let widthpc = "58rem";
              let height = " 32.6rem";
            } else {
              let widthpc = "100%";
              let height = "32vh";
            }
            let player = new Aliplayer(
              {
                id: "videos",
                vid: that.new_videoid,
                playauth: that.new_playauth,
                cover:
                  "https://www.shijianpro.com/Public/file/202001/2020010664132.png",
                width: widthpc,
                height: height,
                autoplay: true,
              },
              function (player) {
                // console.log("播放器创建了");
              }
            );
          } else {
            this.$message({
              message: res.data.messages,
              duration: 1000,
            });
          }
        });
      } else {
        this.$router.push({
          path: "/sjlogin",
        });
      }
    },
    celan(id) {
      if (id == "1") {
        this.$router.push({
          path: "/Zhanping_bishe",
          query: { data: this.$route.query.zpid },
        });
      } else if (id == "2") {
        $("body,html").animate(
          {
            scrollTop: ($("#worksz_sxq").offset().top)-10,
          },
          500
        );
      }else if (id == "3") {
        $("body,html").animate(
          {
            scrollTop: ($("#Chengguo_c_imgallul_works").offset().top)-10,
          },
          500
        );
      } else if (id == "4") {
          $("body,html").animate(
          {
            scrollTop: ($("#Chengguo_ccenter_wokrs").offset().top)-10,
          },
          500
        );
      } else if (id == "5") {
          $("body,html").animate(
          {
            scrollTop: ($(".Sjpinglun").offset().top)-10,
          },
          500
        );
      }
    },
  },
};
document.onscroll = function () {
  let t1 = document.documentElement.scrollTop || document.body.scrollTop; //滚动条
  let t2 = document.documentElement.scrollHeight || document.body.scrollHeight; //滚动条 总长
  let t3 = t2 - t1; //滚动条 距底部距离
  if (t3 < 2026) {
    $(".worksbox_tl").css({
      position: "absolute",
      bottom: "0",
      top: "auto",
    });
  } else {
    $(".worksbox_tl").css({
      position: "fixed",
      bottom: "auto",
      top: "0",
    });
  }
};
</script>
<style>
@import "../assets/css/chengguo.css";
</style>
